import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Input.module.css';

const getCharsLeft = (value, maxChars) => (maxChars ? Math.max(0, maxChars - String(value).length) : 9999);

/**
 * Textarea editor
 */
const Textarea = ({ maxChars, className, singleLine, ...props }) => {
    const Component = singleLine ? 'input' : 'textarea';
    return (
        <>
            <Component
                {...props}
                className={cn(
                    styles.textarea,
                    singleLine && styles.singleLine,
                    !!maxChars && styles.limitedTextarea,
                    className
                )}
                onChange={e => {
                    const charsLeft = getCharsLeft(e.target.value, maxChars);
                    if (charsLeft >= 0) {
                        props.onChange(e);
                    }
                    if (charsLeft < 0) {
                        props.onChange({
                            target: {
                                id: e.target.id,
                                name: e.target.name,
                                value: e.target.value.substr(0, maxChars),
                            },
                        });
                    }
                }}
            />
            {!!maxChars && (
                <div className={styles.charCounter}>
                    <div>{getCharsLeft(props.value, maxChars)}</div>
                </div>
            )}
        </>
    );
};

Textarea.propTypes = {
    /**
     * Max chars limit
     */
    maxChars: PropTypes.number,
    /**
     * Value attr
     */
    value: PropTypes.string,
    /**
     * Class name
     */
    className: PropTypes.string,
};

export default Textarea;
