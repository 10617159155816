import { createSelector } from 'reselect';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import { client, hasUserRole } from 'utils';
import { lang, t } from 'utils/lang';
import { MODULES, OPPORTUNITY_MODULE_FLOW, USER_ROLE } from 'constants.js';
import { ONBOARDING_STEPS } from 'pages/onboarding/onboardingConstant';

export const getDisplayLanguage = state => get(state, 'user.current.data.displayLanguage.language.code', lang());
export const getSSOFields = state => get(state, 'user.current.data.ssoFieldsLog', []);

const getUserRoles = user => get(user, 'roles', []);
const getBaseRoleFromUser = user => get(user, 'baseRole', {});
const getUserTiers = user => get(user, 'tiers', []);

export const getCurrentUser = state => state.user.current.data;
export const getCurrentUserCompany = state => state.user.current.data.company;
export const getCurrentPartnerInfo = state => state.loyaltyProgram.partner.data;
export const getCountryCodes = state => state.countryCode.list.data;

export const getAccountDetails = createSelector([getCurrentUser], currentUser => {
    const { address, id, email, firstName, lastName, phone, nickname } = currentUser;
    const { city, postcode, streetFirstLine, streetSecondLine, state, country } = address || {};
    return {
        id,
        email,
        firstName,
        lastName,
        phone,
        country: currentUser.country && currentUser.country.id,
        dateFormat: currentUser.dateFormat || 'DD/MM/YYYY',
        nickname,
        address: {
            country: country && country.id,
            city,
            postcode,
            streetFirstLine,
            streetSecondLine,
            state,
        },
    };
});

export const getSettingsDetails = createSelector([getCurrentUser], currentUser => {
    const {
        id,
        name,
        timezone,
        displayLanguage,
        contactMe,
        contactMeByEmail,
        contactMeByPhone,
        contactPreference,
        hasConciergeAccess,
        company,
        dateFormat,
        brandAgreement,
    } = currentUser;
    return {
        id,
        name,
        timezone: timezone && timezone.id,
        displayLanguage: displayLanguage && displayLanguage.id,
        contactMe,
        contactMeByEmail,
        contactMeByPhone,
        contactPreference,
        conciergeAccess: hasConciergeAccess ? 'yes' : 'no',
        brandAgreement: brandAgreement ? 'yes' : 'no',
        companyId: company.id,
        dateFormat,
    };
});

export const getSocialAccounts = createSelector([getCurrentUser], currentUser => {
    const { id, socialNetworks } = currentUser;
    return {
        id,
        socialNetworks,
    };
});

export const getCompanyDetails = createSelector([getCurrentUserCompany], company => {
    const { city, postcode, streetFirstLine, streetSecondLine, state, country } =
        company && company.address ? company.address : {};
    const {
        city: city2,
        postcode: postcode2,
        streetFirstLine: streetFirstLine2,
        streetSecondLine: streetSecondLine2,
        state: state2,
        country: country2,
    } = company && company.secondaryAddress ? company.secondaryAddress : {};
    return {
        id: company?.id || null,
        name: company?.name || '',
        email: company?.email || '',
        phone: company?.phone || '',
        url: company?.url || '',
        privacyPolicyType: company?.privacyPolicyType || '',
        privacyPolicyUrl: company?.privacyPolicyUrl || '',
        valueProposition: company?.valueProposition || '',
        address: {
            country: country && country.id,
            city: city || '',
            postcode: postcode || '',
            streetFirstLine: streetFirstLine || '',
            streetSecondLine: streetSecondLine || '',
            state: state || '',
        },
        secondaryAddress: {
            country: country2 && country2.id,
            city: city2,
            postcode: postcode2,
            streetFirstLine: streetFirstLine2,
            streetSecondLine: streetSecondLine2,
            state: state2,
        },
        addressOnAssets: company.addressOnAssets || {},
        logoUrl: company ? company.logoUrl : '',
        useSecondaryAddress: company.useSecondaryAddress || false,
    };
});

const getUserRolesMap = user => {
    const userRoles = getUserRoles(user);
    const baseRole = getBaseRoleFromUser(user);
    const hasRole = name => hasUserRole(name, userRoles);
    const hasBaseRole = name => baseRole.role === name;
    return {
        noRole: !baseRole.role,
        isBaseVendor: hasBaseRole(USER_ROLE.ROLE_VENDOR),
        isBasePartner: hasBaseRole(USER_ROLE.ROLE_PARTNER),
        isBaseReportManager: hasBaseRole(USER_ROLE.ROLE_REPORT_MANAGER),
        isBaseConciergeInternal: hasBaseRole(USER_ROLE.ROLE_CONCIERGE_INTERNAL),
        isDesigner: hasRole(USER_ROLE.ROLE_DESIGNER),
        isPartner: hasRole(USER_ROLE.ROLE_PARTNER) || hasRole(USER_ROLE.ROLE_TEST_PARTNER),
        isVendor: hasRole(USER_ROLE.ROLE_VENDOR) || hasRole(USER_ROLE.ROLE_TEST_VENDOR),
        isGlobalAdmin: hasRole(USER_ROLE.ROLE_GLOBAL_ADMIN),
        isConciergePartner: hasRole(USER_ROLE.ROLE_CONCIERGE_PARTNER),
        isReportManager: hasRole(USER_ROLE.ROLE_REPORT_MANAGER),
        isConciergeInternal: hasRole(USER_ROLE.ROLE_CONCIERGE_INTERNAL),
        isConciergeExternal: hasRole(USER_ROLE.ROLE_CONCIERGE),
        isConcierge: hasRole(USER_ROLE.ROLE_CONCIERGE) || hasRole(USER_ROLE.ROLE_CONCIERGE_INTERNAL),
        isEasyAccessPartner: hasRole(USER_ROLE.ROLE_EA_PARTNER),
        isPartnerAdmin: user.isPartnerAdmin,
        masterAssetProducer: user.masterAssetProducer,
        masterVendor: user.masterVendor,
    };
};

export const getCurrentUserRolesMap = createSelector([getCurrentUser], getUserRolesMap);

const getUserTiersMap = user => getUserTiers(user).map(tier => tier.name);
export const getCurrentUserTiersMap = createSelector([getCurrentUser], getUserTiersMap);
export const getCurrentUserPermissionsMap = createSelector([getCurrentUser], user =>
    getUserTiers(user)
        .filter(tier => tier.isPermission)
        .map(tier => tier.name)
);

export const getUserModulesMap = createSelector([getCurrentUser], currentUser => ({
    [MODULES.ASSET_LIBRARY]: currentUser.isAssetLibraryEnabled || false,
    [MODULES.CAMPAIGN_BUILDER]: currentUser.isCampaignBuilderEnabled || false,
    [MODULES.IDENTIFIER_BUILDER]: currentUser.isIdentifierBuilderEnabled || false,
    [MODULES.MARKETING_STORE]: currentUser.isMarketingStoreEnabled || false,
    [MODULES.SALES_RESOURCES]: currentUser.isSalesResourcesEnabled || false,
    [MODULES.SOCIAL_POSTS]: client.isSocialMediaPostsEnabled(),
    [MODULES.SPIFF]: currentUser.isSpiffEnabled || false,
    [MODULES.LOYALTY_PROGRAM]: currentUser.isLoyaltyProgramEnabled || false,
    [MODULES.ONBOARDING]: currentUser.isOnboardingEnabled || false,
    [MODULES.OPPORTUNITY_MANAGEMENT]: currentUser.client.isOpportunityManagementEnabled || false,
    [MODULES.INCENTIVE]: currentUser.isIncentiveEnabled || false,
    showMarketingStore: currentUser.showMarketingStore || false,
}));

export const isClientLoyaltyProgramEnabled = createSelector(
    [getCurrentUser],
    currentUser => currentUser.client.isLoyaltyProgramEnabled || currentUser.client.isIncentiveEnabled
);

export const isOutlookAddInEnabled = createSelector(
    [getCurrentUser],
    currentUser => currentUser.client.isOutlookAddInEnabled && (currentUser.isSSO || currentUser.isTester)
);

export const getUserModuleMarketingStoreId = createSelector(
    [getCurrentUser],
    currentUser => currentUser.client.modules.filter(item => item.module.module === 'MARKETING_STORE').pop().id || 0
);

export const isTermsAndConditionsAccepted = createSelector(
    [getCurrentUser],
    currentUser => currentUser.termsAndConditionsAccepted || currentUser.isLoginBehind
);

export const getModulesSettings = createSelector([getCurrentUser], currentUser => ({
    marketingStoreSettings: currentUser.client?.marketingStoreSettings || {},
    campaignBuilderSettings: currentUser.client?.campaignBuilderSettings || {},
    opportunityModuleSettings: currentUser.client?.opportunityModuleSettings || {},
    resourceLibrarySettings: currentUser.client?.resourceLibrarySettings || {},
}));

export const getLoyaltyClubs = createSelector(
    [getCurrentUser],
    currentUser =>
        currentUser.client.modules
            .filter(
                item =>
                    item.module.module.indexOf('LOYALTY_PROGRAM_') === 0 ||
                    item.module.module.indexOf('INCENTIVE') === 0
            )
            .map(m => m.module.module.replace('LOYALTY_PROGRAM_', '').toLowerCase()) || []
);

export const getRewardClubPrograms = createSelector(
    [getCurrentUser, getCurrentPartnerInfo],
    (currentUser, currentPartner) => {
        const currentPrograms = {};
        if (!isUndefined(currentPartner.eligiblePrograms)) {
            currentPartner.eligiblePrograms.forEach(eligibleProgram => {
                currentPrograms[eligibleProgram] = {
                    signed: false,
                    isAdmin: false,
                };
            });
        }
        if (!isUndefined(currentPartner.enrolledPrograms)) {
            currentPartner.enrolledPrograms.forEach(enrolledProgram => {
                if (!currentPrograms[enrolledProgram.program]) {
                    currentPrograms[enrolledProgram.program] = {
                        signed: false,
                        isAdmin: false,
                    };
                }

                currentPrograms[enrolledProgram.program].signed = true;
                currentPrograms[enrolledProgram.program].isAdmin = enrolledProgram.user_id === currentUser.id;
            });
        }
        return currentPrograms;
    }
);

export const getReportSettings = createSelector([getCurrentUser], currentUser => ({
    startDate: new Date(currentUser.client.reportStartDate * 1000) || new Date(),
    endDate: new Date(),
}));

export const getCustomPages = createSelector([getCurrentUser], currentUser =>
    (currentUser.customPages || []).map(page => {
        const item =
            page.customPageLanguages
                .filter(pageLanguage => pageLanguage.language === currentUser.displayLanguage.id)
                .pop() || page;

        return {
            ...page,
            name: item.name,
        };
    })
);

const notEmpty = value => value && value.length !== 0;

const getHelpdeskSetting = (type, user) => {
    let settingsWithType = [];

    if (user.client && !isUndefined(user.client[type])) {
        const regionsIds = notEmpty(user.regions) ? user.regions.map(({ id }) => id) : [];
        const languageCode = user.displayLanguage.language.code;
        settingsWithType = user.client[type].filter(item => {
            let isValid;
            if (notEmpty(regionsIds) && notEmpty(item.regions)) {
                isValid = item.regions.filter(region => regionsIds.indexOf(region.id) !== -1).length > 0;
                if (!isValid) {
                    return false;
                }
            }

            if (user.country && user.country.id && notEmpty(item.countries)) {
                isValid = item.countries.filter(country => country.id === user.country.id).length > 0;
                if (!isValid) {
                    return false;
                }
            }

            if (languageCode && notEmpty(item.languages)) {
                isValid = item.languages.filter(language => language.language.code === languageCode).length > 0;
                if (!isValid) {
                    return false;
                }
            }

            return true;
        });

        // Fallback to default one.
        if (settingsWithType.length === 0) {
            settingsWithType = user.client[type].filter(item =>
                item.languages.some(systemLanguage => systemLanguage.language.code === window.pmConfig.baseLanguage)
            );
        }
    }

    if (settingsWithType.length === 0) {
        return { value: '' };
    }

    return settingsWithType[0];
};

function setZendeskLanguageCode(url, user) {
    // This replacement is needed for the Zendesk link to be in the correct language.
    const countryCodeInZendeskFormat = user.displayLanguage.language.code.toLowerCase().replace('_', '-');
    return url.replace('country_code', countryCodeInZendeskFormat);
}

export const getPhoneNumber = createSelector(
    [getCurrentUser],
    currentUser => getHelpdeskSetting('helpdeskPhones', currentUser).value
);

export const getWidget = createSelector(
    [getCurrentUser],
    currentUser => getHelpdeskSetting('helpdeskWidgets', currentUser).value
);

export const getContactUsLink = createSelector([getCurrentUser], currentUser => {
    const setting = getHelpdeskSetting('helpdeskLinks', currentUser);

    if (setting.type === 'email') {
        return `mailto:${setting.value}`;
    }
    if (setting.type === 'default') {
        return setZendeskLanguageCode(setting.value, currentUser);
    }
    return setting.value;
});

export const getKnowledgeBaseLink = createSelector([getCurrentUser], currentUser => {
    const setting = getHelpdeskSetting('helpdeskKnowledgeBases', currentUser);

    if (setting.type === 'default') {
        return setZendeskLanguageCode(setting.value, currentUser);
    }
    return setting.value;
});

export const isSpiffInternalApprover = createSelector(
    [getCurrentUser],
    currentUser => currentUser.spiffInternalApprover
);

export const hasUserApprovalPermission = createSelector([getCurrentUser], currentUser => currentUser.userApproval);

export const hasMasterAssetProducerPermission = createSelector(
    [getCurrentUser],
    currentUser => !!currentUser.masterAssetProducer
);

export const isSpiffLevel2Approver = createSelector(
    [getCurrentUser],
    currentUser => currentUser.spiffLevel2Approver || false
);

export const isSSO = createSelector([getCurrentUser], currentUser => currentUser.isSSO);

export const isSsoEnabled = createSelector(
    [getCurrentUser],
    currentUser => !!currentUser.client?.samlSettings && !!currentUser.client?.samlSettings.isActive
);

export const isSSOUserRegistrationEnabled = createSelector(
    [getCurrentUser],
    currentUser => !!currentUser.client?.samlSettings && !!currentUser.client?.samlSettings.userRegistration
);

export const isSsoBackToIdpEnabled = createSelector(
    [getCurrentUser],
    currentUser =>
        !!currentUser.client?.samlSettings &&
        !!currentUser.client?.samlSettings.isActive &&
        !!currentUser.client.samlSettings.isBackToIdpEnabled
);

export const getBackToIdpSettings = createSelector([getCurrentUser], currentUser => ({
    title: t(currentUser.client.samlSettings.backToIdpTitle),
    url: currentUser.client.samlSettings.backToIdpUrl,
}));

export const clientSlug = createSelector([getCurrentUser], currentUser => currentUser.client.subdomainSlug);

export const canEditUIDOfOtherUser = otherUser =>
    createSelector(
        [getCurrentUser, getCurrentUserRolesMap, isSsoEnabled],
        (currentUser, roles, ssoEnabled) =>
            currentUser.id !== otherUser.id && (roles.isGlobalAdmin || (roles.isVendor && !ssoEnabled))
    );

export const canEditRoleOfOtherUser = otherUser =>
    createSelector([getCurrentUser, getCurrentUserRolesMap], (currentUser, currentUserRoles) => {
        const otherUserRoles = getUserRolesMap(otherUser);
        return (
            (currentUserRoles.isGlobalAdmin || currentUserRoles.isVendor) &&
            currentUser.id !== otherUser.id &&
            (otherUserRoles.noRole ||
                otherUserRoles.isPartner ||
                otherUserRoles.isVendor ||
                otherUserRoles.isDesigner ||
                otherUserRoles.isReportManager ||
                otherUserRoles.isConcierge ||
                otherUserRoles.isConciergePartner)
        );
    });

export const canEditCompanyOfOtherUser = otherUser =>
    createSelector([getCurrentUser], currentUser => currentUser.id !== otherUser.id);

export const canEditTierOfOtherUser = otherUser =>
    createSelector([getCurrentUser], currentUser => {
        const otherUserRoles = getUserRolesMap(otherUser);
        return currentUser.id !== otherUser.id && !otherUserRoles.isDesigner;
    });

export const canEditRegionOfOtherUser = otherUser =>
    createSelector([getCurrentUser], currentUser => currentUser.id !== otherUser.id);

export const canEditApprovalGroupOfOtherUser = otherUser =>
    createSelector([getCurrentUser], currentUser => {
        const otherUserRoles = getUserRolesMap(otherUser);
        return (
            currentUser.id !== otherUser.id &&
            (otherUserRoles.isDesigner || otherUserRoles.isPartner) &&
            currentUser.isGlobalAdminUser
        );
    });

export const canEditAgencyOfOtherUser = otherUser =>
    createSelector([getCurrentUser], currentUser => {
        const otherUserRoles = getUserRolesMap(otherUser);
        return (
            currentUser.id !== otherUser.id &&
            (otherUserRoles.isConciergeExternal || otherUserRoles.isConciergeInternal) &&
            currentUser.isGlobalAdminUser
        );
    });

export const getOnboardingProgress = createSelector([getCurrentUser], currentUser => {
    if (currentUser.client.isOnboardingEnabled) {
        const row = currentUser.fields.filter(field => field.fieldName === 'onboarding_progress').pop();
        if (row) {
            return JSON.parse(row.value);
        }
    }

    return [];
});

export const getOnboardingSteps = createSelector([getOnboardingProgress], progress =>
    ONBOARDING_STEPS.map(step => ({
        number: step.number,
        name: step.name,
        completed:
            step.items.filter(item => progress.filter(key => key === item.key).length === 1).length ===
            step.items.length,
    }))
);

export const selectCurrentUserImages = createSelector([getCurrentUser], currentUser => currentUser.images);

export const getPamInfo = createSelector([getCurrentUser], currentUser => currentUser.pam);

export const getClientMainColor = createSelector([getCurrentUser], currentUser => `#${currentUser.client.mainColour}`);
export const getClientTwentypcColour = createSelector(
    [getCurrentUser],
    currentUser => `#${currentUser.client.twentypcColour}`
);

export const canViewReports = createSelector(
    [getCurrentUser, getUserRolesMap],
    (currentUser, roles) => currentUser.isCampaignBuilderEnabled && !roles.isConciergeInternal
);

export const getOpportunityModuleSettings = createSelector(
    [getCurrentUser, getUserModulesMap],
    (currentUser, modules) => {
        const moduleSettings = { ...(currentUser.client.opportunityModuleSettings || {}) };
        const flow = moduleSettings.flow ?? '';
        const opportunityModuleEnabled = modules[MODULES.OPPORTUNITY_MANAGEMENT];

        return {
            ...moduleSettings,
            isFlowDedicated: opportunityModuleEnabled && OPPORTUNITY_MODULE_FLOW.DEDICATED === flow,
            isFlowStandard: opportunityModuleEnabled && OPPORTUNITY_MODULE_FLOW.STANDARD === flow,
        };
    }
);

export const getUserBaseRole = createSelector([getCurrentUser], currentUser => getBaseRoleFromUser(currentUser));

export const hasFullTemplateBuilderAccess = createSelector(
    [getCurrentUser, getCurrentUserRolesMap],
    (currentUser, roles) => roles.isGlobalAdmin || (roles.isDesigner && !!currentUser.masterAssetProducer)
);

export const hasRestrictedTemplateBuilderAccess = createSelector(
    [getCurrentUser, getCurrentUserRolesMap],
    (currentUser, roles) => roles.isDesigner && !currentUser.masterAssetProducer
);
