import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { client } from '../../../../utils';
import DropDown from '../../../DropDown/DropDown';
import Button from '../../../Button/Button';
import { getWidget, getDisplayLanguage } from '../../../../selectors/userSelectors';

import styles from './Help.module.css';

/**
 * Help button, which using ZenDesk widget.
 */
class Help extends Component {
    // Script loading is on Angular side for now. Roll this back after removing
    // https://static.zdassets.com/ekr/snippet.js?key=${helpdeskWidget} from angular.

    getOffset = (rawDom) => {
        try {
            return rawDom.offset();
        } catch (e) {}
        const body = document.documentElement || document.body;
        const scrollX = window.pageXOffset || body.scrollLeft;
        const scrollY = window.pageYOffset || body.scrollTop;
        const _x = rawDom.getBoundingClientRect().left + scrollX;
        const _y = rawDom.getBoundingClientRect().top + scrollY;
        return { left: _x, top: _y, x: scrollX, y: scrollY };
    };

    needHelp = () => {
        const { mainColor, displayLanguage } = this.props;

        if (typeof window.zE !== 'undefined') {
            window.zE('webWidget', 'updateSettings', {
                webWidget: {
                    color: { theme: `#${mainColor}` },
                },
            });
            window.zE(() => {
                window.zE.show();
                window.zE('webWidget', 'open');
                window.zE('webWidget', 'setLocale', displayLanguage);
            });
        }
    };

    render() {
        const { className } = this.props;

        return (
            <DropDown menuClassName={styles.menu} noIcon className={cn(styles.wrapper, className)}>
                <Button small color={Button.COLOR.PRIMARY} className={styles.button} onClick={this.needHelp}>
                    ?
                </Button>
            </DropDown>
        );
    }
}

Help.propTypes = {
    /**
     * Class name to add to the wrapper
     */
    className: PropTypes.string,
    /**
     * @ignore
     */
    helpdeskWidget: PropTypes.string,
    /**
     * @ignore
     */
    mainColor: PropTypes.string,
    /**
     * @ignore
     */
    displayLanguage: PropTypes.string,
};

const mapStateToProps = (state) => ({
    helpdeskWidget: getWidget(state),
    displayLanguage: getDisplayLanguage(state),
    mainColor: client.getMainColor(),
});

export default connect(mapStateToProps)(Help);
