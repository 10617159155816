import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isFunction from 'lodash/isFunction';
import ReactTooltip from 'react-tooltip';
import styles from './LoadingContainer.module.css';
import Icon from '../Icon/Icon';

const SIZE = {
    REGULAR: 'regular',
    SMALL: 'small',
};

const LoadingContainer = ({ className, children, global, opacity, loading: propLoading, size = SIZE.REGULAR, flex }) => {
    const [initialRender, setInitialRender] = useState(true);
    useEffect(() => {
        setInitialRender(false);
    }, []);
    useEffect(() => {
        if (!propLoading) {
            ReactTooltip.rebuild();
        }
    }, [propLoading]);

    const loading = propLoading || initialRender;

    return (
        <div
            className={cn(
                styles.loadingContainer,
                loading && styles.loading,
                global && styles.global,
                loading && opacity && styles.opaque,
                flex && styles.flex,
                styles[size],
                className
            )}
        >
            {!!loading && (
                <div className={styles.spinnerWrapper}>
                    <div className={styles.spinner}>
                        <Icon kind="loading" className={styles.spin} />
                    </div>
                </div>
            )}
            {(!!opacity || (!opacity && !loading)) && (isFunction(children) ? children() : children)}
        </div>
    );
};

LoadingContainer.propTypes = {
    /**
     * Preferable option to use LoadingContainer is children as a function.
     * In this case children will not be rendered, but not displayed.
     */
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    size: PropTypes.oneOf(Object.values(SIZE)),
    className: PropTypes.string,
    loading: PropTypes.bool,
    global: PropTypes.bool,
    opacity: PropTypes.bool,
    flex: PropTypes.bool,
};

LoadingContainer.SIZE = SIZE;

export default LoadingContainer;
