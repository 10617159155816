import React from 'react';
import PropTypes from 'prop-types';
import useCoBrandableImage from 'pages/manage/Templates/Template/hooks/useCoBrandableImage';
import LoadingContainer from 'components/LoadingContainer/LoadingContainer';

export default function EditableAreaImage({ src, ...rest }) {
    const { url, isCoBrandable, loading } = useCoBrandableImage(src);

    const img = <img src={url} {...rest} />;
    return isCoBrandable && loading ? (
        <LoadingContainer loading opacity>
            {img}
        </LoadingContainer>
    ) : (
        img
    );
}

EditableAreaImage.propTypes = {
    src: PropTypes.string.isRequired,
};
