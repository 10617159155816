import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import mapValues from 'lodash/mapValues';
import { coBrandedTemplateLoadingSelector } from 'pages/campaignInstance/redux/selectors';
import { getCoBrandedSocialPostImage, getCoBrandedTemplate } from 'pages/campaignInstance/redux/actions';
import { SOCIAL_POST_IMAGE_NAME } from 'constants.js';
import isEqual from 'lodash/isEqual';
import useIsSocialPostInstanceEditor from 'hooks/useIsSocialPostInstanceEditor';

const isCoBrandableImage = src => src.slice(-4) === '.pdf';

const IGNORED_IMAGES = { [SOCIAL_POST_IMAGE_NAME]: true };
const IGNORED_TEXTS = { message: true, description: true, title: true };

const coBrandableImageData = data =>
    mapValues(
        {
            texts: data?.texts?.filter(({ name }) => !IGNORED_TEXTS[name]) || [],
            images: data?.images?.filter(({ name }) => !IGNORED_IMAGES[name]) || [],
        },
        items => items.map(({ name, value, options }) => ({ name, value: value || options?.[0]?.value }))
    );

const useCoBrandableImage = src => {
    const [url, setUrl] = useState(src);
    const dispatch = useDispatch();
    const { id, campaignId, templateId } = useParams();
    const loading = useSelector(coBrandedTemplateLoadingSelector);
    const isSocialPostInstance = useIsSocialPostInstanceEditor();
    const isCoBrandable = isCoBrandableImage(src);
    const {
        values: { data },
    } = useFormikContext();
    const [coBrandableData, setCoBrandableData] = useState(coBrandableImageData(data));

    useEffect(() => {
        if (isCoBrandable) {
            setCoBrandableData(prev => {
                const _data = coBrandableImageData(data);
                if (!isEqual(_data, prev)) {
                    return _data;
                }
                return prev;
            });
        }
    }, [isCoBrandable, data]);

    useEffect(() => {
        if (isCoBrandable) {
            (async () =>
                setUrl(
                    await dispatch(
                        isSocialPostInstance
                            ? getCoBrandedSocialPostImage(id, coBrandableData)
                            : getCoBrandedTemplate(templateId, id || campaignId, coBrandableData)
                    )
                ))();
        } else {
            setUrl(src);
        }
    }, [isCoBrandable, id, campaignId, templateId, setUrl, src, coBrandableData, isSocialPostInstance]);

    return { url, loading, isCoBrandable };
};

export default useCoBrandableImage;
