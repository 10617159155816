import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../../pages/spiff/Application/Status/Status.module.css';
import emptyPage from '../../pages/spiff/Application/Status/icon-no-plans.svg';
import emptyUsers from './no-leads.png';
import emptyItems from './no-items.png';
import emptyPoints from './no-points.png';
import emptyDocuments from './no-documents.png';
import emptyRewards from './no-rewards.png';
import emptySurveys from './no-surveys.png';
import emptyUsd from './no-usd.png';
import emptyIdentifier from './no-identifier.png';
import emptyColours from './no-colour.svg';
import emptyButtons from './no-buttons.svg';
import emptyTemeplates from './no-templates.svg';
import emptyTexts from './no-texts.svg';
import emptyFooters from './no-footers.svg';
import emptyForms from './no-forms.svg';
import Button from '../Button/Button';
import InfoBlock from '../InfoBlock/InfoBlock';

/**
 * Display empty page content
 */
const EmptyContent = ({ image, text, action: { text: actionText, ...actionProps }, info }) => (
    <div className={cn(styles.empty, !image && styles.alignLeft)}>
        {!!image && <img src={image} alt="Empty" />}
        <div className={styles.text}>{text}</div>
        {!!info && <InfoBlock className={cn(styles.infoBlock, 'mt-20')}>{info}</InfoBlock>}
        {!!actionText && (
            <Button className={cn(styles.button, !image && styles.noMarginLeft)} icon="plus" {...actionProps}>
                {actionText}
            </Button>
        )}
    </div>
);

EmptyContent.defaultProps = {
    action: {},
};

EmptyContent.IMAGE = {
    PAGE: emptyPage,
    USERS: emptyUsers,
    IDENTIFIER: emptyIdentifier,
    ITEMS: emptyItems,
    POINTS: emptyPoints,
    DOCUMENTS: emptyDocuments,
    REWARDS: emptyRewards,
    SURVEYS: emptySurveys,
    USD: emptyUsd,
    COLOURS: emptyColours,
    BUTTONS: emptyButtons,
    TEMPLATES: emptyTemeplates,
    TEXTS: emptyTexts,
    FOOTERS: emptyFooters,
    FORMS: emptyForms,
};

EmptyContent.propTypes = {
    image: PropTypes.string,
    info: PropTypes.string,
    text: PropTypes.node,
    action: PropTypes.object,
};

export default EmptyContent;
