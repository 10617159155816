import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import isUndefined from 'lodash/isUndefined';
import chunk from 'lodash/chunk';
import { Field, Form, Formik } from 'formik';
import useToggle from 'hooks/useToggle';
import { t } from 'utils';
import ArrowButton from '../ArrowButton/ArrowButton';
import Button from '../Button/Button';
import Col from '../FlexBox/components/Col';
import Input from '../Input/Input';
import Row from '../FlexBox/components/Row';
import LoadingContainer from '../LoadingContainer/LoadingContainer';
import SearchField from '../SearchField/SearchField';
import FormikChangeSubmit from '../FormikChangeSubmit/FormikChangeSubmit';
import EmptySearch from '../EmptySearch/EmptySearch';
import ResultsCount from './ResultsCount';
import styles from './Filters.module.scss';

const findDiff = (a, b, ignoreKeys = []) => {
    if (isEmpty(a) || isEmpty(b)) {
        return [];
    }
    return reduce(
        a,
        (result, value, key) => {
            if (ignoreKeys.includes(key)) {
                return result;
            }

            if (isPlainObject(b) && isUndefined(b[key])) {
                return result;
            }

            if (isPlainObject(a[key])) {
                return [...result, ...findDiff(a[key], b[key])];
            }

            return isEqual(value, b[key]) ? result : result.concat(key);
        },
        []
    );
};

const drawFilters = (filters, loading, className) => (
    <div className={cn(styles.filtersWrapper, 'container', className)}>
        <LoadingContainer loading={loading}>
            {chunk(filters, 3).map((row, rowIndex) => (
                <Row key={`row_${rowIndex}`}>
                    {row.map(({ noplacehoder, ...filter }, filterIndex) => (
                        <Col md={4} key={`filter_${filterIndex}`}>
                            <Field
                                component={Input}
                                padding={Input.PADDING.NONE}
                                type={Input.TYPE.MULTISELECT}
                                hideValidation
                                labelWidth={Input.LABEL_WIDTH.FULL}
                                placeholder={noplacehoder ? null : t('campaign.my_campaigns.no_filters')}
                                id={`filters-${filter.name}`}
                                {...filter}
                            />
                        </Col>
                    ))}
                </Row>
            ))}
        </LoadingContainer>
    </div>
);

function Filters({
    filters,
    search,
    sort,
    totalResults,
    showCount = true,
    initialValues,
    activeFilters,
    loading,
    dataLoading,
    className,
    onChange,
    actions,
    initialOpen,
    buttonStyle,
}) {
    const [opened, toggleOpened] = useToggle(!!initialOpen);
    const filtersChanged = useRef(false);
    const mainFilters = filters.filter(filter => filter.isMain).map(({ isMain, ...rest }) => rest);
    const hasMainFilters = mainFilters.length > 0;
    const additionalFilters = filters.filter(filter => !filter.isMain);

    return (
        <>
            <Formik initialValues={activeFilters || {}} enableReinitialize onSubmit={onChange}>
                {({ values }) => {
                    const ignored = [
                        search?.name,
                        sort?.name,
                        ...mainFilters.map(({ name }) => name),
                        'campaignId',
                        'limit',
                        'offset',
                    ];
                    const appliedFilters = findDiff(initialValues, values, ignored);
                    filtersChanged.current = !!appliedFilters.length || !!values[search.name];
                    const appliedText = (
                        <span className={styles.applied}>
                            {t(
                                hasMainFilters
                                    ? 'marketing_store.dashboard.filters_count'
                                    : 'general.filters.filters_applied',
                                { count: appliedFilters.length }
                            )}
                        </span>
                    );
                    return (
                        <Form className={cn(styles.wrapper, className)}>
                            <div className={styles.filtersContainer}>
                                {hasMainFilters && drawFilters(mainFilters, loading, styles.mainFilters)}
                                <div className={cn(styles.filters, 'container')}>
                                    <div>
                                        <h3 className="sub-title">
                                            {t(
                                                hasMainFilters
                                                    ? 'marketing_store.dashboard.additional_filters_title'
                                                    : 'general.labels.filter'
                                            )}
                                        </h3>
                                        {!!actions && appliedText}
                                    </div>
                                    <div>
                                        {!actions && appliedText}
                                        {!!actions && <div className={styles.applied}>{actions}</div>}
                                        {appliedFilters.length > 0 && (
                                            <Button
                                                color={
                                                    buttonStyle && Button.COLOR[buttonStyle]
                                                        ? Button.COLOR[buttonStyle]
                                                        : Button.COLOR.GRAY
                                                }
                                                tiny
                                                onClick={() => onChange(initialValues)}
                                                icon="cross"
                                            >
                                                {t('campaign.campaign_index.search.form.clear_all_action')}
                                            </Button>
                                        )}
                                        <ArrowButton isOpen={opened} onClick={toggleOpened} />
                                    </div>
                                </div>
                                {opened &&
                                    drawFilters(additionalFilters, loading, hasMainFilters && styles.additionalFilters)}
                            </div>
                            <div className="container">
                                {(!!search || !!sort) && (
                                    <div className={styles.searchContainer}>
                                        {!!sort && (
                                            <Field
                                                component={Input}
                                                padding={Input.PADDING.NONE}
                                                type={Input.TYPE.SELECT}
                                                label={t('campaign.campaign_index.create_campaign_sort.label')}
                                                {...sort}
                                            />
                                        )}
                                        {!!search && (
                                            <div className={styles.searchWrapper}>
                                                {showCount && (
                                                    <ResultsCount
                                                        count={totalResults}
                                                        search={values[search.name]}
                                                        loading={dataLoading}
                                                    />
                                                )}
                                                <Field
                                                    className={styles.searchField}
                                                    component={SearchField}
                                                    padding={Input.PADDING.NONE}
                                                    {...search}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            {!loading && <FormikChangeSubmit delay={500} />}
                        </Form>
                    );
                }}
            </Formik>
            {!dataLoading && !loading && totalResults === 0 && filtersChanged.current && (
                <div className="container">
                    <EmptySearch className={styles.emptySearch} />
                </div>
            )}
        </>
    );
}

Filters.propTypes = {
    /**
     * Initial values for filters. Format: {name: value, ...}
     */
    initialValues: PropTypes.object.isRequired,
    /**
     * Initially selected values for filters (not match with initial filters because this is a values itself).
     */
    activeFilters: PropTypes.object,
    /**
     * Properties for filter inputs. Can be any property for Input
     */
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            data: PropTypes.array.isRequired,
        })
    ).isRequired,
    /**
     * Properties for search input. Can be any property for Input
     */
    search: PropTypes.shape({
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
    }),
    /**
     * Properties for sort input. Can be any property for Input
     */
    sort: PropTypes.shape({
        name: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
    }),
    /**
     * Callback when filter changed
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Filters loading indicator
     */
    loading: PropTypes.bool,
    /**
     * Data loading indicator
     */
    dataLoading: PropTypes.bool,
    /**
     * Data results count
     */
    totalResults: PropTypes.number,
    /**
     * Show results count before search field
     */
    showCount: PropTypes.bool,
    /**
     * Class name for wrapper
     */
    className: PropTypes.string,
    /**
     * Extra elements to place before arrow
     */
    actions: PropTypes.node,
    /**
     * Open the filters initially
     */
    initialOpen: PropTypes.bool,
    /**
     * Style for "clear filters" button
     */
    buttonStyle: PropTypes.string,
};

export default Filters;
