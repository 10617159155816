/* eslint-disable react/no-danger */
import React, { useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';
import Icon from 'components/Icon/Icon';
import HtmlReactParser from 'html-react-parser';
import useIsInstanceEditor from 'hooks/useIsInstanceEditor';
import { parseStyles, renderChildren } from 'pages/campaignInstance/edit/helpers';
import styles from 'pages/campaignInstance/edit/Template.module.scss';
import EditableAreaImage from 'pages/manage/Templates/Template/components/EditableArea/EditableAreaImage';
import useIsHighlightField from 'pages/manage/Templates/Template/hooks/useIsHighlightField';
import activeDataContext from 'context/activeDataContext';
import { ICON_MAP, OPTIONS_GROUPS } from '../../../utils';
import useDataField from '../../hooks/useDataField';
import usePreBuiltEditField from '../../hooks/usePreBuiltEditField';
import usePreBuiltToggleField from '../../hooks/usePreBuiltToggleField';

const PLAIN_NUMBER_REGEXP = /^[\d.]+$/;
const getImageStyleDimension = (dataDimension, dataUnit, attr) => {
    if (dataDimension && dataUnit) {
        return `${dataDimension}${dataUnit}`;
    }
    if (attr && PLAIN_NUMBER_REGEXP.test(attr)) {
        return `${attr}px`;
    }

    return attr;
};

const getImageStyles = (node, imgData) => {
    const { style, width, height } = node.attribs || {};

    return {
        ...(style ? parseStyles(style) : {}),
        maxWidth: getImageStyleDimension(imgData.templateElementWidth, imgData.templateElementWidthUnits, width),
        maxHeight: getImageStyleDimension(imgData.templateElementHeight, imgData.templateElementHeightUnits, height),
    };
};

const nodesMap = {
    'pm-text': OPTIONS_GROUPS.TEXTS,
    'pm-link': OPTIONS_GROUPS.LINKS,
    'pm-image': OPTIONS_GROUPS.IMAGES,
    'pm-social-icons': OPTIONS_GROUPS.SOCIAL_ICONS,
};

const detectNode = node =>
    Object.entries(nodesMap)
        .filter(([key]) => !!node.attribs[key])
        .map(([key, group]) => [group, node.attribs[key]])
        .pop();

const getInt = v => (v ? parseInt(v, 10) : 0);

const getFieldContent = (fieldData, node) => {
    if (node.attribs['pm-social-icons']) {
        return renderChildren(node, { decodeEntities: false });
    }

    if (node.attribs['pm-link']) {
        return fieldData.text || renderChildren(node, { decodeEntities: false });
    }

    if (fieldData.value === null || fieldData.value === undefined) {
        return node.attribs['pm-image'] ? node.attribs.src : renderChildren(node, { decodeEntities: false });
    }

    return fieldData.value;
};

export default function EditableItem({ node, transform }) {
    const Tag = node.name;
    const [dataType, dataKey] = detectNode(node);
    const [{ value: fieldData, name }] = useDataField(dataType, dataKey);
    const { activeData } = useContext(activeDataContext);
    const isCampaignInstance = useIsInstanceEditor();

    const [isMissed] = useIsHighlightField(dataKey, dataType);

    const onToggleField = usePreBuiltToggleField(name);
    const [editField, isEditable] = usePreBuiltEditField(name, dataType, fieldData, fieldData);

    if (!fieldData) {
        console.error(`Field not exists: ${dataType}, ${dataKey}`);
        return null;
    }

    const isToggleable = fieldData.isToggleable || fieldData.toggle;
    const { isActive, isDeleted } = fieldData;

    if (isCampaignInstance && isDeleted) {
        return null;
    }

    let editableOverlay = isEditable ? (
        <span className={styles.editableOverlay}>
            <span className={styles.iconHolder}>
                <Icon kind={isActive ? ICON_MAP[dataType] : ICON_MAP.DELETED} />
            </span>
        </span>
    ) : null;

    if (isDeleted) {
        editableOverlay = (
            <span className={styles.editableOverlay}>
                <span className={cn(styles.iconHolder, styles.bottom)} onClick={onToggleField}>
                    <Icon kind="plus" />
                </span>
                <span className={styles.iconHolder}>
                    <Icon kind={ICON_MAP.DELETED} />
                </span>
            </span>
        );
    }

    const isCurrent = activeData?.name === fieldData.name && activeData?.dataType === dataType;
    const activeClass = cn(
        isCurrent && styles.active,
        (!isActive || isMissed) && !isCurrent && styles.notActive,
        isDeleted && !isCurrent && styles.deleted
    );

    if (dataType === OPTIONS_GROUPS.IMAGES) {
        const imageStyles = getImageStyles(node, fieldData);
        return (
            <div
                className={cn(
                    styles.imageContainer,
                    isEditable && styles.editable,
                    isEditable && styles.imageEditable,
                    isToggleable && styles.toggle,
                    activeClass
                )}
                style={{
                    width: getInt(node.attribs['max-width']) || 'auto',
                    height: getInt(node.attribs['max-height']) || 'auto',
                    display: 'inline-block',
                    ...imageStyles,
                }}
                onClick={editField}
            >
                <EditableAreaImage
                    alt={dataKey}
                    className={node.attribs.class}
                    src={getFieldContent(fieldData, node)}
                    style={imageStyles}
                />
                {editableOverlay}
            </div>
        );
    }

    const { style, class: _class, ...attribs } = node.attribs;

    let fieldContent = getFieldContent(fieldData, node);
    const hasHTML = /<\/?[a-z][\s\S]*>/i.test(fieldContent);
    if ([OPTIONS_GROUPS.LINKS, OPTIONS_GROUPS.SOCIAL_ICONS].includes(dataType) || fieldData.isRichText) {
        // Editable image can be inside links.
        fieldContent = HtmlReactParser(fieldContent, { replace: transform });

        if (dataType === OPTIONS_GROUPS.SOCIAL_ICONS && fieldData.value) {
            fieldContent = fieldContent.filter(el => !isObject(el) || fieldData.value[el.props['pm-social-icon']]);
        }
    }

    return (
        <Tag
            {...attribs}
            style={style ? parseStyles(style) : undefined}
            className={cn(
                _class,
                isEditable && styles.editable,
                isToggleable && styles.toggle,
                hasHTML && dataType === OPTIONS_GROUPS.LINKS && styles.linkWithHTML,
                activeClass
            )}
            onClick={editField}
        >
            {fieldContent}
            {editableOverlay}
        </Tag>
    );
}

EditableItem.propTypes = {
    node: PropTypes.object.isRequired,
    transform: PropTypes.func.isRequired,
};
